'use strict';

const processInclude = require('base/util');
const GDDLHelper = require('./helpers/helper');

var PAGE_GROUP = {
    HOME: 'Home',
    CATEGORY_LANDING: 'Category Landing',
    PRODUCT_LISTING: 'Product Listing',
    PRODUCT_DETAIL: 'Product Detail',
    PRODUCT_SET: 'Product Set',
    SEARCH: 'Search',
    WISHLIST: 'Wishlist',
    CHECKOUT: 'Checkout',
    CART: 'Cart',
    ACCOUNT: 'Account',
    MY_TORFS: 'My Torfs',
    STORE: 'Store',
    CUSTOMER_SERVICE: 'Customer Service',
    OTHER: 'Other'
};

var atrack;

/**
 * Initialize the GDDL tracker
 */
function initAnalyticsTracker() {
    atrack = window.analyticstracker();
    atrack.setHTMLSelectors({
        eventSelector: 'data-event',
        infoSelector: 'data-analytics',
        extendPageInfo: ['url', 'userAgent', 'platform', 'domain', 'referrer']
    });
}

/**
 * Fetches the current pagegroup.
 *
 * @return {string} - The page group.
 */
function getPageGroup() {
    var $page = $('.page');

    if ($page.length > 0) {
        var action = $page.data('action');
        switch (action) {
            case 'Home-Content':
                return PAGE_GROUP.HOME;
            case 'Search-Show':
                var queryString = $page.data('querystring');
                if (queryString.indexOf('cgid') >= 0) {
                    if ($('.container.category-landing').length > 0) {
                        return PAGE_GROUP.CATEGORY_LANDING;
                    }
                    return PAGE_GROUP.PRODUCT_LISTING;
                }

                return PAGE_GROUP.SEARCH;
            case 'Product-Show':
            case 'Product-ShowInCategory':
                return ($('.product-detail').data('producttype') === 'set') ? PAGE_GROUP.PRODUCT_SET : PAGE_GROUP.PRODUCT_DETAIL;
            case 'ProductLists-Detail':
            case 'Wishlist-Show':
                return PAGE_GROUP.WISHLIST;
            case 'Cart-Show':
                return PAGE_GROUP.CART;
            case 'Checkout-Begin':
            case 'Checkout-Login':
            case 'Order-Confirm':
                return PAGE_GROUP.CHECKOUT;
            case 'Register-Show':
            case 'Login-Show':
                return PAGE_GROUP.ACCOUNT;
            case 'Order-History':
            case 'Order-Details':
            case 'Address-List':
            case 'PaymentInstruments-List':
                return PAGE_GROUP.MY_TORFS;
            case 'Stores-Find':
            case 'Stores-Detail':
                return PAGE_GROUP.STORE;
            case 'Account-ContactUs':
                return PAGE_GROUP.CUSTOMER_SERVICE;
            default:
                break;
        }

        if (action && action.indexOf('Account') >= 0) {
            return PAGE_GROUP.MY_TORFS;
        }
    }
    return PAGE_GROUP.OTHER;
}

/**
 * Determines the list element for the analytics JSON.
 * @param {jQuery} $element - The analytics element.
 *
 * @return {string} - The list.
 */
function determineList($element) {
    var list = getPageGroup();

    var $contentList = $element.closest('[data-list-id]');

    if ($contentList && $contentList.length > 0) {
        var listID = $contentList.data('list-id');
        list += ' - ' + listID;
    }

    return list;
}

/**
 * Process a single porduct item.
 * @param {number} index - Current item index
 * @param {jQuery} analyticsElement - Current analytics element
 * @param {Object} ecommerceData - Data object
 * @param {Object} filters - The applied filters
 * @param {Object} detailEcommerceData - Detail object
 */
function processSingleImpressionProduct(index, analyticsElement, ecommerceData, filters) {
    var jsonString = $(analyticsElement).attr('content');

    if (jsonString && jsonString.length > 0 && jsonString !== 'null') {
        var jsonData = JSON.parse(jsonString);
        if (jsonData) jsonData.position = index;

        var compiledList = determineList($(analyticsElement));

        if (compiledList) {
            if ($('.product-set-detail').length) {
                jsonData.list_name = $('.product-set-detail').data('pid');
            } else if (sessionStorage.getItem('stl_source')) {
                jsonData.list_name = sessionStorage.getItem('stl_source');
            } else {
                jsonData.list_name = compiledList;

                if (compiledList === PAGE_GROUP.PRODUCT_LISTING) {
                    jsonData.list_name += ' - ' + GDDLHelper.getBreadCrumbPath();
                }
            }

            if (filters && filters.length > 0) {
                jsonData.filter_selection = filters;
            }

            ecommerceData.commerce.push(GDDLHelper.cleanObject(jsonData));
        }
    }
}

/**
 * Pushes a product click to the data layer.
 * @param {jQuery} $element - The plp tile
 * @param {jQuery} $target - The clicked element
 */
function pushProductClick($element, $target) {
    var $analyticsElement = $element.find('meta[itemprom=analytics]').first();
    var list = determineList($analyticsElement);

    if (list) {
        var jsonObject = GDDLHelper.fetchCommerceData($analyticsElement);
        jsonObject.position = $element.data('position');

        if (list === PAGE_GROUP.PRODUCT_LISTING) {
            list += ' - ' + GDDLHelper.getBreadCrumbPath();
        }

        // Pass the correct variant if clicked on a swatch instead the main product
        let $targetParent = $target.closest('a');
        if ($targetParent.hasClass('js-swatch-circle')) {
            const swatchName = $targetParent.attr('data-swatch-name');
            if (swatchName) {
                jsonObject.name = swatchName;
            }
            const swatchColor = $targetParent.attr('data-swatch-color');
            if (swatchColor) {
                jsonObject.color = swatchColor;
            }
        }

        var ecommerceData = {
            event: 'product-click',
            commerce: GDDLHelper.cleanObject(jsonObject)
        };

        atrack.trackEvent(ecommerceData);
    }
}

/**
 * Pushes a product detail view to the data layer.
 * @param {jQuery} $element - The clicked element.
 */
function pushProductDetailView($element) {
    var $analyticsElement = $element.find('meta[itemprom=analytics]').first();
    var commerceData = GDDLHelper.fetchCommerceData($analyticsElement);
    if (commerceData) {
        var ecommerceData = {
            event: 'product-detail',
            commerce: commerceData
        };

        atrack.trackEvent(ecommerceData);
    }
}

/**
 * Pushes a product add to basket to the data layer.
 * @param {jQuery} $element - The clicked element.
 * @param {number} allCartItems - All items in the cart
 */
function pushAddToBasket($element, allCartItems) {
    var $analyticsElement = $element.find('meta[itemprom=analytics]').first();
    var jsonObject = GDDLHelper.fetchCommerceData($analyticsElement);

    if (jsonObject) {
        jsonObject.quantity = 1;

        var ecommerceData = {
            event: 'product-addtocart',
            commerce: GDDLHelper.cleanObject(jsonObject),
            info: {
                cart_items: []
            }
        };

        if (allCartItems) {
            let itemList = [];
            allCartItems.forEach(item => {
                itemList.push(GDDLHelper.cleanObject(item.tracking));
            });

            if (itemList.length) {
                ecommerceData.info.cart_items = itemList;
            }
        }

        atrack.trackEvent(ecommerceData);
    }
}

/**
 * Pushes product impressions to the data layer. This function will be executed when intersection observer is not supported
 */
function pushProductImpressions() {
    var ecommerceData = {
        event: 'product-impression',
        commerce: []
    };

    $('[data-pid] meta[itemprom=analytics]').each(function (index, analyticsElement) {
        processSingleImpressionProduct(index, analyticsElement, ecommerceData);
    });

    if (ecommerceData.commerce.length > 0) {
        atrack.trackEvent(ecommerceData);
    }
}

/**
 * Push product impressions to data layer when viewed.
 * @param {[IntersectionObserverEntry]} entries - Observer entries
 * @param {IntersectionObserver} observer - The observer
 */
function pushObservableProductImpressions(entries, observer) {
    let filters = {};
    $('.filter-bar li').not('.filter-bar__value--remove-all').each(function () {
        let filter = $(this).data('object');
        filters[filter.id] = filter.title;
    });
    var ecommerceData = {
        event: 'product-impression',
        commerce: []
    };

    entries.forEach(function (entry) {
        if (entry.intersectionRatio <= 0) {
            return;
        }
        var analyticsElement = $(entry.target).find('meta[itemprom=analytics]').first();

        processSingleImpressionProduct($(entry.target).data('index'), analyticsElement, ecommerceData, filters);

        observer.unobserve(entry.target);
    });

    if (ecommerceData.commerce.length > 0) {
        atrack.trackEvent(ecommerceData);
    }
}

/**
 * Add the intersection observer to the product tile DOM elements loaded.
 * When the elements become visible, the intersection observer will execute.
 * @param {NodeList} nodes - the node list containing the product tile elements
 * @param {number} index - The position of the product
 * @param {IntersectionObserver} intersectionObserver - The intersection observer
 */
function initObservableProductImpressions(nodes, index, intersectionObserver) {
    let i = index;
    if (nodes.length > 0) {
        nodes.forEach((node) => {
            let $productChild;
            let $node = $(node);

            // Determine if node itself is the product tile
            if ($node.closest('.product[data-pid]') && $node.closest('.product[data-pid]').length > 0) {
                $productChild = $node.closest('.product[data-pid]');
            } else {
                $productChild = $node.find('.product[data-pid]');
            }

            for (let childsIndex = 0; childsIndex < $productChild.length; childsIndex++) {
                const productChild = $productChild[childsIndex];
                if (!$(productChild).data('index')) {
                    $(productChild).data('index', i);
                }
                intersectionObserver.observe(productChild);
                i++;
            }
        });
    }
}

/**
 * Pushes a product add to basket to the data layer.
 * @param {jQuery} $element - The clicked element.
 * @param {number} allCartItems - All items in the cart
 * @param {boolean} changedQuantity - true if only quantity changed, quantity will be 1 in this case. if false and the whole product is removed then the entire product quantity will be needed.
 */
const pushRemoveFromBasket = ($element, allCartItems, changedQuantity) => {
    const $analyticsElement = $element.find('meta[itemprom=analytics]').first();
    let jsonString = $analyticsElement.attr('content');
    if (jsonString && jsonString.length > 0 && jsonString !== 'null') {
        let jsonObject = JSON.parse(jsonString);

        jsonObject.quantity = changedQuantity ? 1 : Number($element.find('.quantity-form .quantity').val());

        let ecommerceData = {
            event: 'product-removefromcart',
            commerce: GDDLHelper.cleanObject(jsonObject),
            info: {
                cart_items: []
            }
        };

        if (allCartItems) {
            let itemList = [];
            allCartItems.forEach(item => {
                itemList.push(GDDLHelper.cleanObject(item.tracking));
            });

            if (itemList.length) {
                ecommerceData.info.cart_items = itemList;
            }
        }

        atrack.trackEvent(ecommerceData);
    }
};

const initRemoveFromCart = () => {
    $(document).on('cart:afterRemoveProduct', function (event, data) {
        pushRemoveFromBasket(data.lineItemContainer, data.basket.items, data.quantityChange);
    });
};

const initWishlistData = () => {
    $('body').on('wishlist:trackWishlist', (e, data) => {
        let jsonString = data.productContainer.first().find('meta[itemprom=analytics]').attr('content');
        let jsonObject = JSON.parse(jsonString);

        if ($('.product-set-detail').length) {
            jsonObject.list_name = $('.product-set-detail').data('pid');
        } else if (sessionStorage.getItem('stl_source')) {
            jsonObject.list_name = sessionStorage.getItem('stl_source');
        }

        const ecommerceData = {
            event: data.isRemove ? 'product-removefromwishlist' : 'product-addtowishlist',
            commerce: GDDLHelper.cleanObject(jsonObject),
            info: {
                wishlist_items: []
            }
        };

        const allWishlistItems = data.productsInWishlist;

        if (allWishlistItems) {
            let itemList = [];
            allWishlistItems.forEach(item => {
                itemList.push(GDDLHelper.cleanObject(item));
            });

            if (itemList.length) {
                ecommerceData.info.wishlist_items = itemList;
            }
        }

        atrack.trackEvent(ecommerceData);
    });
};

const initCartPageProducts = () => {
    const $page = $('.page');
    if ($page.length > 0) {
        const action = $page.data('action');
        if (action === 'Cart-Show') {
            const eventData = {
                event: 'product-viewcart',
                commerce: GDDLHelper.fetchCommerceData('.js-cart-product-grid .product[data-pid] meta[itemprom=analytics], .js-cart-product-grid .product-info meta[itemprom=analytics]')
            };
            atrack.trackEvent(eventData);
        }
    }
};

const initWishlistPageProducts = () => {
    const $page = $('.page');
    if ($page.length > 0) {
        const action = $page.data('action');
        if (action === 'Wishlist-Show') {
            const eventData = {
                event: 'product-viewwishlist',
                commerce: GDDLHelper.fetchCommerceData('.js-wishlist-product-grid .product-info>meta[itemprom=analytics]')
            };
            atrack.trackEvent(eventData);
        }
    }
};

const initReviewsInfo = () => {
    $('body').on('product:reviewsInfo', function (e, data) {
        var $analyticsElement = $('.product-detail-page').find('meta[itemprom=analytics]').first();
        var commerceData = GDDLHelper.fetchCommerceData($analyticsElement);
        if (commerceData && commerceData.length && data) {
            commerceData[0].review_score = data.rating;
            commerceData[0].review_count = data.amount;

            const eventData = {
                event: 'product-reviewinfo',
                commerce: commerceData
            };

            atrack.trackEvent(eventData);
        }
    });
};

const ProductAnalytics = {
    initShopTheLook: function () {
        if (sessionStorage.getItem('stl_source') && sessionStorage.getItem('stl_source_needs_removal')) {
            sessionStorage.removeItem('stl_source');
            sessionStorage.removeItem('stl_source_needs_removal');
        } else if (sessionStorage.getItem('stl_source')) {
            // We are on a PDP so we need to remove the shop-the-look source immediately on the next page visit
            sessionStorage.setItem('stl_source_needs_removal', true);
        }
    },
    initReviewsInfo: initReviewsInfo,
    initWishlistData: initWishlistData,
    initRemoveFromCart: initRemoveFromCart,
    initAnalyticsTracker: initAnalyticsTracker,
    initCartPageProducts: initCartPageProducts,
    initWishlistPageProducts: initWishlistPageProducts,
    initProductImpressions: function () {
        if ('IntersectionObserver' in window) {
            try {
                const intersectionObserver = new IntersectionObserver(function (entries) {
                    pushObservableProductImpressions(entries, intersectionObserver);
                }, {
                    threshold: [0.75]
                });

                const mutationConfig = { childList: true };
                // List all target containers that need to be watched. Every $('.product[data-pid]') will be observed
                const mutationTargets = [
                    ...document.querySelectorAll('.product-grid'),
                    ...document.querySelectorAll('.product-slider'),
                    ...document.querySelectorAll('.js-cart-product-grid'),
                    ...document.querySelectorAll('.js-wishlist-product-grid'),
                    ...document.querySelectorAll('.set-items')
                ];
                const mutationObserver = new MutationObserver(function (mutations) {
                    // Loop the mutations that are triggered defined in mutationTargets
                    mutations.forEach(function (mutation) {
                        // When clicking more button, add the current loaded items to the 'position' attribute (specific for lister)
                        const pageSize = $('.grid-footer').data('page-size') || 0;
                        const pageNumber = $('.grid-footer').data('page-number') || 0;
                        let currentLoadedItems = pageSize * (pageNumber - 1);

                        // Async page load (more button, filters, sort) - observe loaded product tiles
                        const nodes = mutation.addedNodes;
                        initObservableProductImpressions(nodes, currentLoadedItems + 1, intersectionObserver);
                    });
                });

                if (mutationTargets && mutationTargets.length > 0) {
                    mutationTargets.forEach(function (mutationTarget) {
                        // Initial page load - observe loaded product tiles
                        const nodes = mutationTarget.childNodes;

                        const pageSize = $('.grid-footer').data('page-size') || 0;
                        const pageNumber = $('.grid-footer').data('page-number') || 0;
                        let currentLoadedItems = pageSize * (pageNumber - 1);

                        initObservableProductImpressions(nodes, currentLoadedItems + 1, intersectionObserver);

                        // Observe further added product-tiles
                        mutationObserver.observe(mutationTarget, mutationConfig);
                    });
                }
            } catch (error) {
                // DO NOTHING
            }
        } else {
            // Execute product impressions when intersection observer is not supported
            pushProductImpressions();

            $(document).on('search:showMore', function () {
                pushProductImpressions();
            });
        }
    },
    initProductClicks: function () {
        $(document).on('click', '.product-tile[data-pid], .product-info[data-pid], .set-item[data-pid]', function (e) {
            const $target = $(e.target);

            // Otherwise send click event when clicked on link
            const isWishlist = $target.hasClass('js-wishlistTile') || $target.parents('.js-wishlistTile').length || $target.parents('.remove-from-wishlist').length;
            const isProductSet = $target.parents('.set-detail-size').length;
            if (!isWishlist && !isProductSet && $target.closest('a') && $target.closest('a').length > 0) {
                pushProductClick($(this), $target);
            }

            return true;
        });
    },
    initAddToCart: function () {
        $(document).on('product:afterAddToCart', function (event, data) {
            pushAddToBasket(data.$productContainer, data.cart.items);
        });
    },
    updateMetaTagAfterAttributeSelect: () => {
        $('body').on('product:afterAttributeSelect', (e, response) => {
            const product = response && response.data ? response.data.product : null;
            const $productContainer = response.container;
            if (!product || !product.tracking || !$productContainer || !$productContainer.length) return;

            const $analyticsMetaTag = $productContainer.find('meta[itemprom=analytics]');
            if (!$analyticsMetaTag.length) return;

            $analyticsMetaTag.prop('content', JSON.stringify(product.tracking));
        });
    },
    initProductDetailView: function () {
        const $productDetailView = $('.product-detail-page');
        if ($productDetailView && $productDetailView.length > 0) {
            pushProductDetailView($productDetailView);
        }
    },
    initProductAttrSelector: function () {
        // We first sent the selectsize event when a user clicked "".product-attributes .size-button.size-value"
        // The stock amounts weren't updated so quick, so we changed this to fire when product:afterAttributeSelect is fired
        // product:afterAttributeSelect is just fired when the analytics data etc are updated
        $('body').on('product:afterAttributeSelect', (e, data) => {
            if (!data.gddl) return;
            var $target = $(data.target);
            const sizeValue = $target.data('attr-value');
            const newProductId = $target.data('product-id') || $target.data('product-sku');

            const $analyticsElement = $target.parents('.product-detail').find('meta[itemprom=analytics]');
            let commerceData = GDDLHelper.fetchCommerceData($analyticsElement);
            let productData;

            if (commerceData) {
                commerceData.forEach(productObj => {
                    if (productObj.id == newProductId || productObj.group_id == newProductId) { // eslint-disable-line eqeqeq
                        productData = productObj;
                    }
                });
            }

            if (productData) {
                // Extra check
                // Only push a select-size event if the attribute is an actual size attribute
                if (sizeValue) {
                    const ecommerceData = {
                        event: 'product-selectsize',
                        commerce: productData
                    };

                    atrack.trackEvent(ecommerceData);
                } else {
                // Push a new product-impression in order to show the product has changed
                // for example on change color selector on STL pages
                    var ecommerceData = {
                        event: 'product-impression',
                        commerce: []
                    };

                    if ($('.product-set-detail').length) {
                        productData.list_name = $('.product-set-detail').data('pid');
                    } else if (sessionStorage.getItem('stl_source')) {
                        productData.list_name = sessionStorage.getItem('stl_source');
                    }

                    ecommerceData.commerce.push(productData);
                    atrack.trackEvent(ecommerceData);
                }
            }
        });
    }
};

$(() => {
    processInclude(ProductAnalytics);
});
